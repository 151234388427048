import QRCode from "qrcode";

export async function generateQrCode(text) {
  try {
    const result = await QRCode.toDataURL(text, { width: 520 });
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}
