import "babel-polyfill";
import App from "./app";
import Common from "./Common";
import EventSelect from "./eventSelect";
import { generateQrCode } from "./helper/qrcode";
import "../css/index.css";

App.init();
Common.registerEvent();

/*
 * init customer page
 */
if ($("#customer-detail-container").length) {
  import("./customer").then(({ Customer, ...rest }) => {
    console.log(Customer);
    Customer.init();
  });
}

/*
 * init player page
 */
if ($("#PlayersForm").length > 0) {
  import("./players").then(({ Players, ...rest }) => {
    Players.init();
  });
}

/*
 * init upload image page
 */
if ($("#formUploadPhoto").length > 0) {
  import("./uploadPhoto").then(({ UploadImage, ...rest }) => {
    UploadImage.init();
  });
}

/*
 * init personal profile page
 */
if ($("#formPersonalProfile").length > 0) {
  import("./personalProfile").then(({ PersonProfile, ...rest }) => {
    PersonProfile.init();
  });
}

/*
 * init login page
 */
if ($("#login-container").length > 0) {
  import("./login").then(({ Login, ...rest }) => {
    Login.init();
  });
}

/*
 * init event report
 */
if ($("#evtReportContainer").length > 0) {
  import("./eventReport").then(({ EventReport, ...rest }) => {
    EventReport.init();
  });
}

/*
 * init print event terminal
 */
if ($("#printEventReceipt").length > 0) {
  import("./printEventReceipt").then(({ PrintEventReceipt, ...rest }) => {
    PrintEventReceipt.init();
  });
}

/*
 * init player result page
 */
if ($("#playerResultsForm").length > 0) {
  import("./playerResult").then(({ PlayerResult, ...rest }) => {
    PlayerResult.init();
  });
}

/*
 * init prize structure page
 */
if ($("#prize-structure-container").length > 0) {
  import("./prizeStructure").then(({ PrizeStructure, ...rest }) => {
    console.log(PrizeStructure);
    PrizeStructure.init();
  });
}

/*
 * init tournament clock
 */
if ($("#tournamentClockContainer").length > 0) {
  import("./tournamentClock").then(({ TournamentClock, ...rest }) => {
    TournamentClock.init();
  });
}

/*
 * init tournament clock servant
 */
if ($("#tournamentClockServantContainer").length > 0) {
  import("./tournamentClockServant").then(
    ({ TournamentClockServant, ...rest }) => {
      TournamentClockServant.init();
    }
  );
}

export const modules = {
  EventSelect,
  Utility: {
    generateQrCode,
  },
  sidebar: App.sidebar,
};
