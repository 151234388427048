import IndexedDatabase from './indexedDb';
import bootbox from 'bootbox';
var EventSelect = (function (bootbox, idb) {
    var previousEvent = $('#previousEventTmp');

    return {
        displayPreviousEvent: function (title) {

            bootbox.dialog({
                message: title ? '<p>' + title + '</p>' + previousEvent.html() : previousEvent.html(),
                onEscape: false,
                closeButton: false,
                buttons: {
                    cancel: {
                        label: 'No',
                        className: 'btn-default'
                    },
                    ok: {
                        label: 'Yes',
                        className: 'btn-primary',
                        callback: function () {
                            $('#formPreviousEvent').submit();
                        }
                    }
                }
            });

        },
        hasPreviousEvent: previousEvent.length > 0        
    }
}(bootbox, IndexedDatabase));

export default EventSelect;