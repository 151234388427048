import _ from 'underscore';
var IndexedDatabase = (function (window, $, _) {
    var _currentEventId = $('input.hidden-event-id[type=hidden]');
    var _indexedDb = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB;
    var _IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction;
    var _IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange;

    function initDatabase(eventId, onDatabaseInitiated) {
        //open (or create) the database
        var openRequest = openDatabase();

        openRequest.onupgradeneeded = createDb;

        openRequest.onsuccess = function (e) {
            var thisDb = e.target.result;
            thisDb.onerror = function (e) {
                console.log("***ERROR***");
                console.dir(e.target);
            }
            handleInitDb(thisDb, eventId, onDatabaseInitiated);            
        }
    }

    function openDatabase() {
        var openRequest = _indexedDb.open('customers', 1);
        return openRequest;
    }

    function createDb(e) {
        var thisDb = e.target.result;
        if (!thisDb.objectStoreNames.contains('customers')) {
            var objectStore = thisDb.createObjectStore('customers', {
                keyPath: 'id',
                autoIncrement: true
            });
            objectStore.createIndex('eventId', 'EventId', {
                unique: false
            });
            objectStore.createIndex('search', 'SearchKey', {
                unique: false
            });
        }
    }

    function handleInitDb(db, eventId, onDatabaseInitiated) {
        var eventRange = _IDBKeyRange.only(eventId);
        var transaction = db.transaction(['customers'], 'readonly');        
        var countEvent = transaction.objectStore('customers').index('eventId').count(eventRange);
        countEvent.onsuccess = function (e) {
            var totalPlayers = e.target.result;
            //event has not loaded yet
            if (e.target.result == 0) {
                $.get('/Home/Event/Preload', {
                    eventId: eventId
                }, function (response) {
                    totalPlayers = response.length;
                    var transaction = db.transaction(['customers'], 'readwrite');
                    var store = transaction.objectStore('customers');                    
                    transaction.oncomplete = function(){
                        if (onDatabaseInitiated) onDatabaseInitiated(totalPlayers);
                    }
                    _.each(response, function (customer, index) {
                        customer.SearchKey = customer.FirstName.trim() + ' ' + customer.LastName.trim() + ' (' + customer.CardNumber.trim() + ')';
                        customer.EventId = eventId;
                        store.put(customer);                        
                    });
                    db.close(); 
                });
            }else{
                if (onDatabaseInitiated) onDatabaseInitiated(totalPlayers);
                db.close();
            }                        
        }
    }

    function searchCustomer(searchKey, eventId, onSearchCompleted) {
        var open = openDatabase();
        open.onupgradeneeded = createDb;
        open.onsuccess = function (e) {
            var db = e.target.result;
            db.onerror = function (e) {
                console.log("***ERROR***");
                console.dir(e.target);
            }
            handleSearch(db, eventId, searchKey, onSearchCompleted);
        }
    }

    function handleSearch(db, eventId, query, callback) {
        var transaction = db.transaction(['customers'], 'readonly');
        var store = transaction.objectStore('customers');
        var index = store.index('eventId');
        var eventRange = _IDBKeyRange.only(eventId);

        var result = [];
        index.openCursor(eventRange).onsuccess = function (e) {
            console.log(performance.now());
            var cursor = e.target.result;
            if (cursor) {
                if (cursor.value.SearchKey.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(cursor.value);
                }
                cursor.continue();
            }
            console.log(performance.now());
        }

        transaction.oncomplete = function () {
            if (callback) {
                callback(result);
            }
            db.close();
        }
    }

    function deleteDatabase(callback){
        const deleteRequest = _indexedDb.deleteDatabase('customers');
        deleteRequest.onerror = function(event){
            console.log("Error deleting database.");
        }

        deleteRequest.onsuccess = function(){
            if(callback) callback();
        }

        deleteRequest.onblocked = function(){
            console.log('DB blocked');
        }
    }

    return {
        initDatabase: initDatabase,
        search: searchCustomer,
        deleteDatabase: deleteDatabase
    }


}(window, $, _))

export default IndexedDatabase;