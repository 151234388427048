import _ from "underscore";
import * as Toastr from "toastr";
import "toastr-style";

var Common = (function () {
  var registerEvent = function () {
    $(document).on("click", "ul.sidebar-nav > li > a", function () {
      $("ul.sidebar-nav > li").removeClass();
      $(this).parent().addClass("active");
    });

    $(document).on("click", "#closeEvent", function (e) {
      e.preventDefault();

      $.ajax({
        url: "/Home/CloseEvent",
        type: "POST",
        beforeSend: function (xhr) {
          xhr.overrideMimeType("application/json");
        },
        datatype: "json",
        success: function (data) {
          if (data != undefined) {
            if (data.Status == 1) {
              $("#closeEvent").attr("disabled", "disabled");
              $("#eventPlayerList select.TableNumber").attr(
                "disabled",
                "disabled"
              );
            } else {
              $("#closeEvent").removeAttr("disabled");
              $("#eventPlayerList select.TableNumber").removeAttr("disabled");
            }
          }
        },
      });
    });
  };

  function applyLoading(selector) {
    $(selector).removeClass("hidden");
  }

  function removeLoading(selector) {
    $(selector).addClass("hidden");
  }

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function setCookie(name, value) {
    var expires = "";
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function formatMoney(
    amount,
    decimalCount = 2,
    decimal = ".",
    thousands = ",",
    dollarSign = "$"
  ) {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        dollarSign +
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
      return "-";
    }
  }

  function formatDate(date) {
    var monthNames = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + "/" + monthNames[monthIndex] + "/" + year;
  }

  const allStates = [
    {
      name: "ACT",
      id: 8,
    },
    {
      name: "NSW",
      id: 2,
    },
    {
      name: "NT",
      id: 7,
    },
    {
      name: "QLD",
      id: 5,
    },
    {
      name: "SA",
      id: 4,
    },
    {
      name: "TAS",
      id: 6,
    },
    {
      name: "VIC",
      id: 1,
    },
    {
      name: "WA",
      id: 3,
    },
  ];

  function updatePlayerFlag(
    gameMembershipId,
    $row,
    onUpdateStart,
    onUpdateFinish
  ) {
    var flag1 = $row.find("td .TextFlag1").val();
    if (flag1 == undefined) {
      flag1 = $row.find("td .CheckFlag1").is(":checked") ? 1 : 0;
    }

    var flag2 = $row.find("td .TextFlag2").val();
    if (flag2 == undefined) {
      flag2 = $row.find("td .CheckFlag2").is(":checked") ? 1 : 0;
    }

    var flag3 = $row.find("td .TextFlag3").val();
    if (flag3 == undefined) {
      flag3 = $row.find("td .CheckFlag3").is(":checked") ? 1 : 0;
    }

    var flag4 = $row.find("td .TextFlag4").val();
    if (flag4 == undefined) {
      flag4 = $row.find("td .CheckFlag4").is(":checked") ? 1 : 0;
    }

    var flag5 = $row.find("td .TextFlag5").val();
    if (flag5 == undefined) {
      flag5 = $row.find("td .CheckFlag5").is(":checked") ? 1 : 0;
    }

    if (!!onUpdateStart) {
      onUpdateStart();
    }

    const request = $.ajax({
      url: "/Home/Player/UpdatePlayerFlag",
      type: "POST",
      beforeSend: function (xhr) {
        xhr.overrideMimeType("application/json");
      },
      data: {
        GameMembershipId: gameMembershipId,
        Flag1: flag1,
        Flag2: flag2,
        Flag3: flag3,
        Flag4: flag4,
        Flag5: flag5,
      },
      datatype: "json",
      success: function (data) {
        //update succeeded
        Toastr.success("Flag updated", "Success", {
          positionClass: "toast-top-center",
        });
      },
      error: function () {
        Toastr.error("Failed to update flag, please try again.", "Error", {
          positionClass: "toast-top-center",
        });
      },
      complete: function () {
        if (!!onUpdateFinish) onUpdateFinish();
      },
    });
    return request;
  }

  const localStorageKeyCollection = {
    BRAND_NAME: "brand",
    BRAND_NAME_LONG: "brandLong",
    EVENT_DISPLAY_NAME: "eventDisplayName",
    RANDOM_DRAW: "randomDraw",
    START_CLOCK: "startClock",
    START_COUNT_DOWN: "startCD",
    START_DATE: "startDate",
    ROUND_TYPE: "RoundType",
  };

  function formatOrdinal(i) {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }

  return {
    registerEvent: registerEvent,
    applyLoading: applyLoading,
    removeLoading: removeLoading,
    getParameterByName: getParameterByName,
    getCookie,
    setCookie,
    allStates,
    formatMoney,
    formatDate,
    formatOrdinal,
    updatePlayerFlag,
    localStorageKeyCollection,
  };
})();

export default Common;
